<template>
    <div>
<img
src="../assets/img/xaxaxlogo.png"
class="nG"
/>
<button
@click="adminActive=!adminActive"
class="btnG"
>ADMIN </button>
<button
@click="$router.push('db')"
v-if="this.$store.state.log"
>OPEN DASHBOARD </button>
<div
v-if="adminActive"
class="diver"
>
<img
src="../assets/img/xaxaxlogo.png"
class="G"
/>
<input
v-model="email"
type="email"
id="email"
placeholder="user"

/>
<input
v-model="password"
type="password"
id="password"
placeholder="password"

/>
<button @click="signed">ENTER</button>
</div>
    </div>
</template>

<script>
import db from '../utilities/firebaseConfig';

import { getAuth, signInWithEmailAndPassword } from "firebase/auth"
import { mapState, mapMutations } from 'vuex';
    export default {
        name:'AdminComp',
        data(){
            return{
                adminActive:false,db,
               
                psswd:'',
                email:'',
                password:''

            }
        }
            ,
            computed:{
            ...mapState['log'],
            ...mapMutations['insider']
        },
        methods:{
            seeArt(){},
            addArt(){
                
            },


            signed(){
                signInWithEmailAndPassword(
                    getAuth(),this.email,this.password
                ).then(
              
                    ()=>{
                        this.adminActive=!this.adminActive,
                        this.$store.commit('insider'),
                        alert('You are in!')},
                    this.email='',
                this.password=''
                ).catch(
                    (e)=>{
                        console.log(e)
                        alert('Wrong Password');
                    }
                )

            },

        }
    }
</script>

<style lang="css" scoped>
input{
    border-radius: 5px;
    width:100px;
    margin:5px;
}
.btnG{
    z-index:100;
    position: absolute;
    right:4%;
    top:4%;
    width:30px;
    padding:2px;
background-color: grey;
font-size: 7px;
color: rgb(195, 195, 195);
border:solid 1px slategray;
border-radius: 5px;
box-shadow: 1px 3px 10px grey;
}
.btnG:hover{
   padding:4px;
   color: white;
    border:solid 1px rgb(0, 0, 0);
    
}
.btnG:active{
    background-color: black;
}
.nG{
display: flex;
align-self: flex-end;
    width:30px;
    padding:2px;

}

.G{

    width:100px;

    vertical-align:middle;

}
.diver{
    margin-top: 200px;
}

</style>