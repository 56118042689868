<template>
    <div>
    <nav>

    <ul>
    <li>
      <router-link to="/">Home</router-link> 
    </li>
    <li>
      <router-link to="/about">About</router-link> 
    </li>
    <li>
      <router-link to="/link1">Freelancing</router-link> 
    </li>
    <li>
      <router-link to="/link2">Multimedia Projects</router-link> 
    </li>

    

    </ul>

    </nav>

    </div>
</template>

<script>


    export default {
        name:'NavBar'
    }
</script>

<style lang="css" scoped>
ul{
    list-style-type:none;
    text-align: left;
    margin: 0;
}
</style>