<template>
  <div id="app">
<NavBar/>
<AdminComp/>
    <router-view/>
  </div>
</template>
<script>
import NavBar from '../src/components/NavBar.vue'
import AdminComp from '../src/components/AdminComp.vue'
export default{
  name:'app',
  components:{
    NavBar, AdminComp
  }
  
}

</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin:0 auto;
  background-color: #c4c4c4;
  border-radius: 5px;
}


</style>
